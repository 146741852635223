import { useToast } from 'primevue/usetoast'

const useMessage = function (delay) {
  const toast = useToast()

  const life = delay | 10000
  const actions = {
    info (summary, detail) {
      toast.add({ severity: 'info', summary, detail, life })
    },
    success (summary, detail) {
      toast.add({ severity: 'success', summary, detail, life })
    },
    warn (summary, detail) {
      toast.add({ severity: 'warn', summary, detail, life })
    },
    error (summary, detail) {
      toast.add({ severity: 'error', summary, detail, life })
    }
  }

  return { ...actions }
}

export { useMessage }
