<template>
  <div class="layout-wrapper layout-menu-lenze layout-topbar-lenze layout-menu-horizontal layout-menu-active">
    <div class="pages-body login-page flex flex-column">
      <div class="layout-topbar shadow-4">
        <div class="layout-topbar-left p-d-flex" routerLink="/login">
          <a class="layout-topbar-logo" href="/#/login">
            <img id="logo" src="layout/images/logo-light.svg" alt="autocbm-logo" style="height: 2.25rem">
          </a>
        </div>
      </div>

      <Toast position="top-center"/>

      <div class="login-form align-self-center mt-auto mb-auto">
        <form @submit.prevent="handleLogin(!v$.$invalid)" class="p-fluid">
          <div class="pages-panel card flex flex-column px-6">
            <div class="pages-header px-3 py-1">
              <h2>{{ $t('app.authentication.action.login.title') }}</h2>
            </div>

            <div class="input-panel flex flex-column mt-6 px-3">
              <div class="p-field">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-envelope" :class="{ 'p-error': v$.username.$invalid && formSubmitted }"/>
                  </span>
                  <span class="p-float-label">
                    <InputText type="text" id="username" v-model="v$.username.$model" :class="{ 'p-invalid': v$.username.$invalid && formSubmitted }"/>
                    <label for="username" :class="{ 'p-error': v$.username.$invalid && formSubmitted }">
                      {{ $t('app.authentication.form.label.username') }}
                    </label>
                    <InlineMessage v-if="v$.username.$invalid && formSubmitted"/>
                  </span>
                </div>
              </div>

              <div class="p-field">
                <div class="p-inputgroup mt-3 mb-6">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-lock" :class="{ 'p-error': v$.password.$invalid && formSubmitted }"/>
                  </span>
                  <span class="p-float-label">
                    <InputText type="password" id="password" v-model="v$.password.$model" :class="{ 'p-invalid': v$.password.$invalid && formSubmitted }"/>
                    <label for="password" :class="{ 'p-error': v$.password.$invalid && formSubmitted }">
                      {{ $t('app.authentication.form.label.password') }}
                    </label>
                    <InlineMessage v-if="v$.password.$invalid && formSubmitted"/>
                  </span>
                </div>
              </div>
            </div>

            <Button type="submit" :label="$t('app.authentication.action.login.label')" :loading="loginForm.loading" class="login-button mb-3 px-3"/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, ref, onMounted} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {useMessage} from '@/dockone/app/message'
import {useVuelidate} from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import messages from './login-i18n'

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const {t} = useI18n({useScope: 'global', messages})
    const message = useMessage()

    const rules = {
      username: {required},
      password: {required}
    }
    const loginForm = reactive({
      username: '',
      password: '',
      loading: false
    })
    const v$ = useVuelidate(rules, loginForm)

    const formSubmitted = ref(false)
    const formActions = {
      handleLogin(isFormValid) {
        formSubmitted.value = true
        if (isFormValid) {
          loginForm.loading = true
          store.dispatch('authentication/login', {...loginForm})
              .then(() => {
                store.dispatch('autocbm/projects/checkGrafanaSession').then(() => {
                  message.success(t('app.authentication.action.login.success.summary'), t('app.authentication.action.login.success.detail'))
                  formActions.resetLoginForm()
                  if (route.query.redirect) {
                    router.push(route.query.redirect)
                  } else {
                    router.push('/')
                  }
                })
              })
              .catch(() => {
                loginForm.username = null
                loginForm.password = null
                formSubmitted.value = true
                message.error(t('app.authentication.action.login.error.summary'), t('app.authentication.action.login.error.detail'))
              })
              .finally(() => loginForm.loading = false)
        }
      },
      resetLoginForm() {
        loginForm.username = ''
        loginForm.password = ''
        formSubmitted.value = false
      }
    }

    onMounted(() => {
      if (route.query.sessionExpired) {
        message.warn(t('app.authentication.action.login.warning.summary'), t('app.authentication.action.login.warning.detail'))
      }
    })

    return {t, v$, loginForm, formSubmitted, ...formActions}
  }
}
</script>

<style scoped>
.login-form {
  min-width: 450px;
}
</style>
