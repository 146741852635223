const messages = {
  de: {
    app: {
      authentication: {
        form: {
          label: {
            username: 'Benutzername',
            password: 'Passwort'
          }
        },
        action: {
          login: {
            title: 'Anmeldung',
            label: 'Anmelden',
            success: {
              summary: 'Login erfolgreich',
              detail: 'Benutzer \'{username}\' wurde erfolgreich angemeldet.'
            },
            warning: {
              summary: 'Sitzung abgelaufen!',
              detail: 'Die Sitzung ist abgelaufen und Sie müssen sich erneut anmelden.'
            },
            error: {
              summary: 'Anmeldung fehlgeschlagen!',
              detail: 'Möglicherweise ist der Benutzername oder das Passwort falsch.'
            }
          },
          logout: {
            label: 'Abmelden'
          }
        }
      }
    }
  },
  en: {
    app: {
      authentication: {
        form: {
          label: {
            username: 'Username',
            password: 'Password'
          }
        },
        action: {
          login: {
            title: 'Login',
            label: 'Login',
            success: {
              summary: 'Login successful',
              detail: 'User \'{username}\' has been successfully logged in.'
            },
            warning: {
              summary: 'Session expired!',
              detail: 'The session has expired and you need to log in again.'
            },
            error: {
              summary: 'Login failed!',
              detail: 'The username or password may be incorrect.'
            }
          },
          logout: {
            label: 'Logout'
          }
        }
      }
    }
  }
}

export default messages
